<template>
  <Layout>
    <div class="container">
      <div class="table-responsive mt-5 pedidos">
        <h1 class="mb-5 fw-bold">Historial de ordenes</h1>

        <table class="table" v-if="ordenes.length > 0">
          <thead class="thead-dark">
            <tr>
              <th scope="col"># Pedido</th>
              <th scope="col">Total</th>
              <th scope="col">Forma de pago</th>
              <th scope="col">Order</th>
              <th scope="col">Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="orden of ordenes" :key="orden.id">
              <th scope="row">{{ orden.id }}</th>
              <td >{{ precio(orden.pedido) }}  {{ restaurante.moneda }}</td>
                        
              <td>
                {{
                  orden.pedido.paymentData
                    ? order.paymentData.nombre
                    : "Efectivo"
                }}
              </td>
              <td>Mirar orden</td>
              <td class="completado" :v-if="orden.pedido.success">
                <i class="bi bi-check-circle-fill"> Completado</i>
              </td>
            </tr>

            <!-- <tr>
              <th scope="row">9874</th>
              <td>56.79€</td>
              <td>Efectivo</td>
              <td>Mirar orden</td>
              <td class="fallo">
                <i class="bi bi-x-circle-fill"></i>
                Cancelado
              </td>
            </tr> -->
          </tbody>
        </table>

        <h3 class="text-center" v-else>No se ha realizado ninguna orden</h3>
      </div>
    </div>
  </Layout>
</template>

<script>
import { useStore } from "vuex";

import Layout from "@/layout/Layout.vue";
import { computed } from "@vue/reactivity";

export default {
methods: {
 
   precio: function (value) {
   var suplemento = 0;
   if (value.AplicarSupMesa)
        suplemento = this.restaurante.suplemento;
    else
        if (value.SuplementoExtra !== undefined)
            suplemento = value.SuplementoExtra;

   var price = value.total;
   if (/^(\d+|(\.\d+))(\.\d+)?%$/.test(suplemento)) {
        suplemento = suplemento.toString().replace(/,/g, '.');
        suplemento = suplemento.substring(0,suplemento.length-1);
        suplemento = Number(price)*Number(suplemento)/100;
        }
   else
       price= Number(price) + Number(suplemento);
  if (this.restaurante.general.redondeo)
     {
     price = Math.round(price*10)/10;
     }   
    if (this.restaurante.moneda !=='XAF')
      return Number(price).toFixed(2)
    else
        return Number(price).toFixed(0)
    
    }
  },
  components: {
    Layout,
  },

  setup() {
    const store = useStore();
const restaurante = computed(() => store.state.restaurante.restaurante);
    const ordenes = computed(() => store.state.ordenes.ordenes);

    return {
      ordenes,
      restaurante
    };
  },
};
</script>

<style lang="scss" scoped>
.pedidos {
  @media (max-width: 698px) {
    font-size: 11px;
  }

  .completado {
    color: #28a745;
    font-weight: bold;
  }

  .fallo {
    color: #dc3545;
    font-weight: bold;
  }
}
</style>
